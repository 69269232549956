import * as React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout/layout";
import "../theme/style.scss";
import { useMemo } from "react";
import useLayoutGeneration from "../core/hooks/useLayoutGeneration";
import PageHeader from "../components/layout/header/pageHeader";
import PageContent from "../components/layout/content";
import {
  FrameBlock100,
  FrameSectionRow,
} from "../components/basics/frame/frameBlocks";
import ButtonContainer from "../components/basics/button/buttonContainer";
import Button from "../components/basics/button/button";
import "../css/index.scss";

const IndexPage = ({ data }) => {
  const pageData = useMemo(() => data.contentfulOldal, [data]);
  const contents = useMemo(
    () => pageData?.tartalomTpus?.tartalom || [],
    [pageData]
  );
  const [generator, resolver] = useLayoutGeneration();
  const generatedContent = useMemo(() => {
    const generatedList = generator(contents);
    const componentList = generatedList.map(resolver);
    return componentList;
  }, [contents, generator, resolver]);

  return (
    <Layout>
      <PageHeader pageData={pageData} />
      <PageContent>
        {generatedContent}
        <FrameSectionRow>
          <FrameBlock100 wrapperClassName="boxless-cta">
            <h3>Still having questions?</h3>
            <p className="text-center">
              Visit our FAQ page to find the answers.
            </p>
            <ButtonContainer>
              <Button text={"Visit our FAQ"} url={"/faq"} />
            </ButtonContainer>
          </FrameBlock100>
        </FrameSectionRow>
      </PageContent>
    </Layout>
  );
};

export const query = graphql`
  query generalIndexQuery($contentfulId: String = "index") {
    contentfulOldal(slug: { eq: $contentfulId }) {
      slug
      cm
      alcm
      tartalomTpus {
        ... on ContentfulIndexTartalomTipus {
          id
          header {
            bortkp {
              gatsbyImageData(layout: CONSTRAINED)
              url
            }
            lers
            title
            primaryCta {
              ... on ContentfulCtaGomb {
                ctaGombLink {
                  ... on ContentfulOldal {
                    slug
                    kategria {
                      slug
                    }
                  }
                  ... on ContentfulOldalKategoria {
                    slug
                  }
                }
                ctaGombSzveg
                externalCtaGombLink
              }
            }
            secondaryCta {
              ... on ContentfulCtaGomb {
                ctaGombLink {
                  ... on ContentfulOldal {
                    slug
                    kategria {
                      slug
                    }
                  }
                  ... on ContentfulOldalKategoria {
                    slug
                  }
                }
                ctaGombSzveg
                externalCtaGombLink
              }
            }
          }
          tartalom {
            ... on ContentfulIndexSection {
              token: contentful_id
              themeColoured
              szekcicm: cm {
                raw
              }
              ComponentContent: tartalom {
                ... on ContentfulFaq {
                  id
                  questionBlock: krdsek {
                    cm
                    lers
                  }
                  internal {
                    type
                  }
                }
                ... on ContentfulCtaBox {
                  cm
                  szveg
                  ctaGomb {
                    ... on ContentfulCtaGomb {
                      ctaGombLink {
                        ... on ContentfulOldal {
                          slug
                          kategria {
                            slug
                          }
                        }
                        ... on ContentfulOldalKategoria {
                          slug
                        }
                      }
                      ctaGombSzveg
                      externalCtaGombLink
                    }
                  }
                  internal {
                    type
                  }
                }
                ... on ContentfulYoutubeVideoBeagyazas {
                  id
                  internal {
                    type
                  }
                  contentfulCm
                  transparentImage
                  bortkp {
                    contentful_id
                    gatsbyImageData(layout: CONSTRAINED)
                  }
                  yotubeVideoUrl
                }
                ... on ContentfulScrolling2OszloposSor {
                  reverse
                  sorok {
                    id
                    contentful_id
                    alcm
                    cm
                    kp {
                      contentful_id
                      gatsbyImageData(layout: CONSTRAINED)
                    }
                    nagythatKp
                    internal {
                      type
                    }
                    oszlopTartalom: tartalom {
                      raw
                    }
                    ctaGomb {
                      ... on ContentfulCtaGomb {
                        ctaGombLink {
                          ... on ContentfulOldal {
                            slug
                            kategria {
                              slug
                            }
                          }
                          ... on ContentfulOldalKategoria {
                            slug
                          }
                        }
                        ctaGombSzveg
                        externalCtaGombLink
                      }
                    }
                  }
                  internal {
                    type
                  }
                }
              }
              internal {
                type
              }
            }
            ... on ContentfulReviewSlider {
              contentful_id
              cm
              themeColoured
              reviewerName1
              reviewStars1
              text1 {
                text1
              }
              reviewerName2
              reviewStars2
              text2 {
                text2
              }
              reviewerName3
              reviewStars3
              text3 {
                text3
              }
              reviewerName4
              reviewStars4
              text4 {
                text4
              }
              reviewerName5
              reviewStars5
              text5 {
                text5
              }
              reviewerName6
              reviewStars6
              text6 {
                text6
              }
              reviewerName7
              reviewStars7
              text7 {
                text7
              }
              reviewerName7
              reviewStars7
              text7 {
                text7
              }
              reviewerName8
              reviewStars8
              text8 {
                text8
              }
              reviewerName9
              reviewStars9
              text9 {
                text9
              }
              reviewerName10
              reviewStars10
              text10 {
                text10
              }
              reviewerName11
              reviewStars11
              text11 {
                text11
              }
              reviewerName12
              reviewStars12
              text12 {
                text12
              }
              reviewerName13
              reviewStars13
              text13 {
                text13
              }
              reviewerName14
              reviewStars14
              text14 {
                text14
              }
              reviewerName15
              reviewStars15
              text15 {
                text15
              }
              internal {
                type
              }
            }
            ... on ContentfulKiemeltTartalom {
              tartalomKategria {
                kategria
                slug
                internal {
                  type
                }
              }
              cm
              alcm
              ComponentContent: tartalom {
                kategria {
                  slug
                  kategria
                }
                cm
                alcm
                slug
                tartalomTpus {
                  ... on ContentfulElerhetosegeinkTartalomTipus {
                    id
                    header {
                      bortkp {
                        gatsbyImageData(layout: CONSTRAINED)
                        url
                      }
                    }
                  }
                  ... on ContentfulServicesTartalomTpus {
                    id
                    header {
                      bortkp {
                        gatsbyImageData(layout: CONSTRAINED)
                        url
                      }
                    }
                  }
                  ... on ContentfulPricingTartalomTipus {
                    id
                    header {
                      bortkp {
                        gatsbyImageData(layout: CONSTRAINED)
                        url
                      }
                    }
                  }
                  ... on ContentfulInstructorsTartalomTipus {
                    id
                    header {
                      bortkp {
                        gatsbyImageData(layout: CONSTRAINED)
                        url
                      }
                    }
                  }
                  ... on ContentfulGeneralTartalomTipus {
                    id
                    header {
                      bortkp {
                        gatsbyImageData(layout: CONSTRAINED)
                        url
                      }
                    }
                  }
                }
                internal {
                  type
                }
              }
              internal {
                type
              }
            }
          }
        }
      }
    }
  }
`;

export default IndexPage;
